

export interface Login_i18n {
    login_header: string
    login_docTypeLabel: string 
    login_docNumberLabel: string 
    login_passLabel: string
    login_enterButton: string
    login_registerLink: string
}

// IMPORTANT -- Cada vez que se agregue un nuevo campo a la interfaz hay que agregar manualmente ese campo al Enum.
export enum Login_i18n_Keys {
    login_header= 'login_header', 
    login_docTypeLabel= 'login_docTypeLabel' ,
    login_docNumberLabel= 'login_docNumberLabel' ,
    login_passLabel= 'login_passLabel',
    login_enterButton= 'login_enterButton',
    login_registerLink= 'login_registerLink'
}

