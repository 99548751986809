export interface Confirm_i18n {
    Confirm_header: string 
    intro: string 
    sendingRequest: string 
    sendingRequestDescription: string 
    appointmentConfirmed: string
    thanksForUsingOurPortal: string 
    requestDenied: string
}

export enum Confirm_i18n_Keys {
    Confirm_header= 'Confirm_header' ,
    intro= 'intro' ,
    sendingRequest= 'sendingRequest' ,
    sendingRequestDescription= 'sendingRequestDescription' ,
    appointmentConfirmed= 'appointmentConfirmed',
    thanksForUsingOurPortal= 'thanksForUsingOurPortal' , 
    requestDenied='requestDenied'
}