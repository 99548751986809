
export interface MyAppointments_i18n {
    my_header: string 
    my_nextAppointments: string
    my_history: string
    my_newAppointmentButton: string 
}

export enum MyAppointments_i18n_Keys {
    my_header= 'my_header' ,
    my_nextAppointments= 'my_nextAppointments',
    my_history= 'my_history',
    my_newAppointmentButton= 'my_newAppointmentButton'
}