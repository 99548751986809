import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import trans_es from './i18n/es/es.ts'

// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
        resources: {
            es: {
                translation: trans_es
            }, 
            en: {}
        },
        lng: 'es',
        fallbackLng: 'es',
        debug: true,

        interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        }
  });


export default i18n;