import React, { Fragment, useEffect, useState } from "react";
import { Slot } from "../types/Booking/Slot";
import {
  IColumn,
  PrimaryButton,
  Stack,
  DetailsList,
  SelectionMode,
} from "office-ui-fabric-react";
import ConfirmBooking from "./ConfirmDialog";
import { useStoreActions, Actions, useStoreState, State } from "easy-peasy";
import { RootState } from "../store/storeModel/RootState";
import moment from "../services/moment";
import LoadingSeparator from "./LoadingSeparator";
import { SlotBookingStatus } from "../types/Booking/SlotBookingStatus";
import { Redirect } from "react-router";
import SignOut from "./SignOut";
import { SorterInputWithColumns } from "../types/SorterInputWithColumns";
import { useTranslation } from "react-i18next";
import { Search_i18n_Keys } from "../i18n/models/Search.i10n";
import { Common_i18n_keys } from "../i18n/models/Common.i18n";
import { OnlyFor } from "../types/OnlyFor";

export interface ResultProps {}

const Result: React.FC<ResultProps> = () => {
  const BookedStatus: string = 'booked';

  const myAppointments = useStoreState(
    (state: State<RootState>) => state.root.myAppointments
  );

  const getMyAppointments = useStoreActions(
    (actions: Actions<RootState>) => actions.root.getMyAppointments
  );

  const [timeFilter] = useState({ when: OnlyFor.Future });

  const IsTruncated = (
    scheduleAppointmentStartDate: Date | undefined,
    scheduledAppointmentEndDate: Date | undefined,
    scheduleAppointmentStatus: string,
    availableAppointmentStartDate: Date,
    availableAppointmentEndDate: Date
  ) => {
    return !(
      scheduleAppointmentStartDate !== undefined &&
      scheduledAppointmentEndDate !== undefined &&
      availableAppointmentEndDate > scheduleAppointmentStartDate &&
      availableAppointmentStartDate < scheduledAppointmentEndDate &&
      scheduleAppointmentStatus === BookedStatus
    );
  };

  const availableSlots = (state: State<RootState>) => {
    return state.root.slots.filter((availableAppointments) => {
      return myAppointments.every((scheduledAppointments) => {
        return IsTruncated(
          scheduledAppointments?.start,
          scheduledAppointments?.end,
          scheduledAppointments.status,
          availableAppointments.date,
          availableAppointments.endDate
        );
      });
    });
  };

  useEffect(() => {
    getMyAppointments(timeFilter.when);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFilter]);

  const slots = useStoreState((state: State<RootState>) =>
    availableSlots(state)
  );

  const toggleConfirmPanel = useStoreActions(
    (state: Actions<RootState>) => state.root.toggleConfirmPanel
  );
  const switchConfirmStatus = useStoreActions(
    (state: Actions<RootState>) => state.root.switchConfirmStatus
  );
  const sortSlots = useStoreActions(
    (actions: Actions<RootState>) => actions.root.sortSlots
  );
  const { t } = useTranslation();

  const [navigate, setNavigate] = useState({ go: false, to: "" });

  const sortByColumn = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): SorterInputWithColumns => {
    const newColumns: IColumn[] = data.columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    return {
      columns: newColumns,
      sorter: {
        columnKey: currColumn.fieldName as string,
        isSortedDescending: currColumn.isSortedDescending as boolean,
      },
    };
  };

  const columns: IColumn[] = [
    {
      key: "doctor",
      name: t(Common_i18n_keys.practitioner),
      fieldName: "practitionerName",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: Slot) => {
        return (
          <Fragment>
            <span aria-describedby={"tooltip" + item.id}>
              {item.practitionerName}
            </span>
          </Fragment>
        );
      },
      onColumnClick: (ev, col) => {
        const sorting = sortByColumn(ev, col);
        setData({ columns: sorting.columns });
        sortSlots(sorting.sorter);
      },
    },
    {
      key: "location",
      name: t(Common_i18n_keys.location),
      fieldName: "locationName",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: Slot) => {
        return <span>{item.locationName}</span>;
      },
      onColumnClick: (ev, col) => {
        const sorting = sortByColumn(ev, col);
        setData({ columns: sorting.columns });
        sortSlots(sorting.sorter);
      },
    },
    {
      key: "fecha",
      name: t(Common_i18n_keys.date),
      fieldName: "date",
      minWidth: 250,
      maxWidth: 250,
      onRender: (item: Slot) => {
        return <span>{moment(item.date).format("LLLL")}</span>;
      },
      onColumnClick: (ev, col) => {
        const sorting = sortByColumn(ev, col);
        setData({ columns: sorting.columns });
        sortSlots(sorting.sorter);
      },
    },
    {
      key: "actions",
      name: "",
      fieldName: "",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: Slot) => {
        return (
          <Fragment>
            <PrimaryButton
              text={t(Search_i18n_Keys.setBooking)}
              onClick={() => {
                toggleConfirmPanel(item.id);
                switchConfirmStatus({
                  key: item.id,
                  value: SlotBookingStatus.ON_CONFIRM,
                });
              }}
            ></PrimaryButton>
            <ConfirmBooking slot={item}></ConfirmBooking>
          </Fragment>
        );
      },
    },
  ];

  const [data, setData] = useState({ columns });

  const goMyAppointments = () => {
    setNavigate({ go: true, to: "/myappointments" });
  };

  if (navigate.go) return <Redirect to={navigate.to} />;

  return (
    <Fragment>
      <Stack className="results info-back">
        <div style={{ margin: "13px 20px 0 20px" }}>
          <SignOut
            styles={{ float: "right", marginTop: "15px", marginLeft: "10px" }}
          ></SignOut>
          <PrimaryButton
            style={{ float: "right", marginTop: "15px" }}
            text={t(Search_i18n_Keys.goMyAppointments)}
            onClick={goMyAppointments}
          ></PrimaryButton>
          <h3>Espacios disponibles</h3>
        </div>

        <LoadingSeparator />
        <div
          style={{ height: "450px", overflowY: "scroll", marginBottom: "10px" }}
        >
          {slots.length > 0 ? (
            <DetailsList
              items={slots}
              columns={data.columns}
              setKey="set"
              isHeaderVisible={true}
              selectionMode={SelectionMode.none}
            />
          ) : (
            <p>{t(Search_i18n_Keys.noRecordMessage)}</p>
          )}
        </div>
      </Stack>
    </Fragment>
  );
};

export default Result;
