import { Search_i18n } from "../../models/Search.i10n";


const search_es : Search_i18n = {
    availableSpots: 'Espacios disponibles', 
    goMyAppointments: 'Ir a mis citas', 
    header: 'Busque el servicio que desea agendar', 
    noRecordMessage: 'No existen registros', 
    requestedDate: 'Fecha solicitada',
    setBooking: 'Reservar'
}

export default search_es