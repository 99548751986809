import React from "react";
import { Route, Redirect } from "react-router";
import { useStoreState, State } from "easy-peasy";
import { RootState } from "../store/storeModel/RootState";

export interface ProtectedRouteProps {
  component: any;
  path: string;
}

const ProtectedRoute: React.SFC<ProtectedRouteProps> = ({
  component: Component,
  path,
}) => {
  const userIsLoggedIn = useStoreState(
    (state: State<RootState>) => state.root.userIsLoggedIn
  );

  return (
    <Route
      exact
      path={path}
      render={(props) =>
        userIsLoggedIn ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default ProtectedRoute;
