export interface Search_i18n {
    header: string,
    requestedDate: string, 
    availableSpots: string, 
    noRecordMessage: string, 
    goMyAppointments: string 
    setBooking: string
}

export enum Search_i18n_Keys {
    header= 'header',
    requestedDate= 'requestedDate', 
    availableSpots= 'availableSpots', 
    noRecordMessage= 'noRecordMessage', 
    goMyAppointments= 'goMyAppointments' ,
    setBooking= 'setBooking'
}