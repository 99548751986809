export interface Register_i18n {
  header: string;
  checkMyData: string;
  missingEmailPassword: string;
  iHaveReadTermsAndConditions: string;
  iAcceptBeenContacted: string;
  checkMyIdentity: string;
  iHaveReadEmailPassword: string;
}

export enum Register_i18n_Keys {
  header = "header",
  checkMyData = "checkMyData",
  iHaveReadTermsAndConditions = "iHaveReadTermsAndConditions",
  iAcceptBeenContacted = "iAcceptBeenContacted",
  checkMyIdentity = "checkMyIdentity",
  missingEmailPassword = "missingEmailPassword",
  iHaveReadEmailPassword = "iHaveReadEmailPassword",
}
