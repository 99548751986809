import React, { Fragment } from "react";
import { CustomError } from "../types/CustomError";

export interface ErrorDetailProps {
  error: CustomError;
}

const ErrorDetail: React.FunctionComponent<ErrorDetailProps> = ({ error }) => {
  console.log(error);
  return (
    <Fragment>
      <h4>Ha ocurrido un error, estamos trabajando para solucionarlo</h4>
    </Fragment>
  );
};

export default ErrorDetail;
