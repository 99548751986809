import { Login_i18n } from "../../models/Login.i18n";

const login_es : Login_i18n = {
    login_header: "Ingrese sus datos personales", 
    login_docTypeLabel: "Tipo de documento", 
    login_docNumberLabel: 'Número de documento', 
    login_passLabel: 'Contraseña', 
    login_enterButton: 'Entrar', 
    login_registerLink: '¿Sin contraseña o la olvidaste?', 
}

export default login_es 