import { ProgressIndicator } from "office-ui-fabric-react";
import React, { Fragment } from "react";
import { useStoreState, State } from "easy-peasy";
import { RootState } from "../store/storeModel/RootState";

export interface LoadingSeparatorProps {}

const LoadingSeparator: React.FunctionComponent<LoadingSeparatorProps> = () => {
  const axiosIsOnRequest = useStoreState(
    (state: State<RootState>) => state.root.axiosIsOnRequest
  );

  return (
    <Fragment>
      <div style={{ margin: "15px 0" }}>
        {axiosIsOnRequest ? (
          <ProgressIndicator></ProgressIndicator>
        ) : (
          <ProgressIndicator percentComplete={100}></ProgressIndicator>
        )}
      </div>
    </Fragment>
  );
};

export default LoadingSeparator;
