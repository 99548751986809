import { Confirm_i18n } from "../../models/Confirm.i18n";

const confirm_es : Confirm_i18n = {
    Confirm_header: 'Confirmación de cita', 
    intro: 'Por favor, revise y confirme los datos de su cita.', 
    appointmentConfirmed: 'Cita confirmada', 
    thanksForUsingOurPortal: 'Gracias por usar nuestro portal', 
    sendingRequest: 'Enviando petición de agenda...', 
    sendingRequestDescription: 'Por favor, espere mientras agendamos su cita.', 
    requestDenied: 'Solicitud denegada.'
}

export default confirm_es