import axios, { AxiosError } from "axios";
import { ErrorInfo } from "react";
import store from "../store/store";
import { ClientsConfig } from "../types/ClientsConfig";
import { CustomError } from "../types/CustomError";

const APIURL = (window as any)._env_APIURL as string;
const clients = (window as any)._env_CLIENTS as ClientsConfig[];

const subdomain = window.location.host.split(".")[0];

const currentClient = clients.find(
  (c) => c.key.toLowerCase() === subdomain.toLowerCase()
);

if (!currentClient) {
  setTimeout(() => {
    store.dispatch.root.setStoreError({
      error: new Error(`No se pudo obtener la configuración para ${subdomain}`),
      info: {} as ErrorInfo,
    });
  }, 500);
}

const axiosService = axios.create({
  baseURL: APIURL,
  withCredentials: false,
  headers: {
    Authorization: `Bearer ${currentClient && currentClient.authToken}`,
    "Content-Type": "application/json",
    "Client-Id": currentClient && currentClient.clientId,
    "External-Id": currentClient && currentClient.externalId,
    KeyWebPortal: currentClient && currentClient.keyWebPortal,
  },
});

const useToogleLoadingIndicator = (axiosIsOnRequest: boolean): void => {
  store.dispatch.root.setAxiosIsOnRequest(axiosIsOnRequest);
};
const resetStoreError = () => {
  store.dispatch.root.setStoreError({} as CustomError);
};

axiosService.interceptors.request.use(
  function (config) {
    resetStoreError();
    useToogleLoadingIndicator(true);
    return config;
  },
  function (error: AxiosError) {
    debugger;
    useToogleLoadingIndicator(false);
    store.dispatch.root.setStoreError({
      error,
      info: {
        componentStack: error.stack ? error.stack : "Error en la petición",
      },
    });
  }
);

axiosService.interceptors.response.use(
  function (config) {
    useToogleLoadingIndicator(false);
    return config;
  },
  function (error) {
    debugger;
    useToogleLoadingIndicator(false);
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      return Promise.reject(error);
    } else {
      store.dispatch.root.setStoreError({
        error,
        info: {
          componentStack: error.stack ? error.stack : "Error en la petición",
        },
      });
    }
  }
);

export default axiosService;
