import { i18nResources } from "../models/i18nResources";
import login from "./components/login.es";
import myappointments from "./components/myappointments.es";
import common from "./common.es";
import search from "./components/search.es";
import confirm from "./components/confirm.es";
import cancel from "./components/cancel.es";
import register from "./components/register.es";


const translation_es: i18nResources = {
        bienvenida: "Bienvenido/a {{name}}, a continuación sus próximas citas:", 
        ...login, 
        ...myappointments, 
        ...common, 
        ...search, 
        ...confirm, 
        ...cancel,
        ...register
}

export default translation_es
