import { createStore } from "easy-peasy";
import { RootState } from "./storeModel/RootState";
import { Booking } from "../types/Booking/Booking";
import { User } from "../types/User";
import { AppointmentReceived } from "../types/HL7/AppointmentReceived";
import { CustomError } from "../types/CustomError";

import thunks from "./thunks";
import actions from "./actions";
import computed from "./computed";

const store = createStore<RootState>({
  root: {
    booking: {} as Booking,
    entities: [],
    especialities: [],
    locations: [],
    partitioners: [],
    slots: [],
    filteredSlots: [],
    filters: {},
    serviceTypes: [],
    axiosIsOnRequest: false,
    documentTypes: [],
    myAppointments: [] as AppointmentReceived[],
    emailPassword: null,
    loginError: "",
    storeError: {} as CustomError,

    //thunks, actions, computed
    ...thunks,
    ...actions,
    ...computed,
  },
});

if (localStorage.getItem("citasuser")) {
  let userObj = JSON.parse((localStorage.getItem("citasuser") as string) || "");
  store.getActions().root.setUser(userObj as User);
}

export default store;
