import { Cancel_i18n } from "../../models/Cancel.i18n";

const cancel_es: Cancel_i18n = {
    areYouSure: '¿Está seguro que desea cancelar la cita?', 
    introRaison: 'Por favor, explique porque desea cancelar la cita.', 
    raisonPlaceHolder: 'Escriba su razón para cancelar la cita.', 
    appointmentCancelled: 'Cita cancelada', 
    sendingCancelRequest: 'Enviando solicitud de cancelación...', 
    waitWhileCancelling: 'Por favor, espere mientras cancelamos su cita.',
    yesImSureButton: 'Si, estoy seguro.', 
    cancelRequestDenied: 'Solicitud de cancelación denegada.'

}

export default  cancel_es