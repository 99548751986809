import { Common_i18n } from "../models/Common.i18n";

const common_es: Common_i18n = {
  speciality: "Especialidad",
  specialityPlaceholder: "Escoja una especialidad",
  cancel: "Cancelar",
  date: "Fecha",
  location: "Sede",
  locationPlaceholder: "Escoja una sede",
  practitioner: "Profesional",
  practitionerPlaceholder: "Escoja un profesional",
  serviceType: "Tipo de Servicio",
  serviceTypePlaceholder: "Escoja el tipo de servicio",
  goBack: "Atrás",
  search: "Buscar",
  signOut: "Salir",
  imcompleteFormFields: "Hay campos incompletos",
  all: "Todos",
  pacient: "Paciente",
  phoneNumber: "Teléfono",
  confirm: "Confirmar",
  finish: "Terminar",
  tryAgain: "Por favor, intente nuevamente.",
  close: "Cerrar",
};

export default common_es;
