import { Register_i18n } from "../../models/Register.i18n";

const register_es: Register_i18n = {
  header: "Obtener acceso al portal",
  checkMyData: "Solicitar contraseña",
  iHaveReadTermsAndConditions:
    "He leído y acepto los términos y condiciones sobre el uso de mi información personal.",
  iAcceptBeenContacted:
    "Acepto que me envíen información a través de mis canales de contacto.",
  missingEmailPassword:
    "El paciente no tiene un correo electrónico registrado, por favor comunícate con nosotros y actualiza tus datos básicos.",
  checkMyIdentity: "Comprobar mi identidad",
  iHaveReadEmailPassword:
    "En los próximos minutos le enviaremos las instrucciones para ingresar al correo",
};

export default register_es;
