export interface Common_i18n {
    speciality: string 
    specialityPlaceholder: string 
    practitioner: string 
    practitionerPlaceholder: string
    location: string 
    locationPlaceholder: string
    date: string
    cancel: string
    serviceType: string
    serviceTypePlaceholder: string,
    goBack: string 
    search: string
    signOut: string
    imcompleteFormFields : string
    all: string 
    pacient: string
    phoneNumber: string 
    confirm: string 
    finish: string
    tryAgain: string
    close: string

}

//IMPORTANTE -- Cada vez que se agregue un nuevo campo a la interfaz es necesario agregarlo al ENUM
export enum Common_i18n_keys {
    speciality= 'speciality' ,
    specialityPlaceholder = 'specialityPlaceholder',
    practitioner= 'practitioner' ,
    practitionerPlaceholder='practitionerPlaceholder',
    location= 'location' ,
    locationPlaceholder = 'locationPlaceholder',
    date= 'date',
    cancel= 'cancel', 
    serviceType = 'serviceType', 
    serviceTypePlaceholder = 'serviceTypePlaceholder',
    goBack = 'goBack', 
    search = 'search', 
    signOut = 'signOut', 
    imcompleteFormFields = 'imcompleteFormFields',
    all = 'all', 
    pacient = 'pacient', 
    phoneNumber = 'phoneNumber', 
    confirm = 'confirm', 
    finish='finish', 
    tryAgain='tryAgain', 
    close= 'close'
}