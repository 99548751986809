import { BookingAppComputed } from "./storeModel/BookingAppComputed";
import { computed } from "easy-peasy";

const computeds: BookingAppComputed = {
  userIsLoggedIn: computed((state) =>
    state.booking && state.booking.user ? true : false
  ),
  userFullName: computed((state) => {
    const user =
      state.booking && state.booking.user ? state.booking.user : null;
    if (!user) return "";
    let name = user.firstName !== "" ? user.firstName : "";
    name += user.secondName !== "" ? " " + user.secondName : "";
    name += user.familyName !== "" ? " " + user.familyName : "";
    name += user.lastName !== "" ? " " + user.lastName : "";
    return name;
  }),
};

export default computeds;
