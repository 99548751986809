import { SearchFilter } from "../types/Filters/SearchFilter";
import moment from "../services/moment";
import { BookingDto } from "../types/Booking/BookingDto";
import { Appointment } from "../types/HL7/Appointment";

const prepScheduleQuery = function (payload: SearchFilter) {
  let query = "_count=100";
  query += payload.locationId > 0 ? "&Location=" + payload.locationId : "";
  query +=
    payload.practitionerId !== "*"
      ? "&Practitioner=" + payload.practitionerId
      : "";
  query += payload.specialityId > 0 ? "&Specialty=" + payload.specialityId : "";
  query += payload.date
    ? "&Start=ge" + moment(payload.date).format("YYYY-MM-DD")
    : "";
  return query;
};

function mapBookingDtoToAppoinment(dto: BookingDto): Appointment {
  let dateText = moment(dto.slot.date).format("LLL");
  let data: Appointment = {
    extension: [
      { id: "numero-autorizacion", valueString: "NA-1163" },
      { id: "fecha-deseada-pacient", valueString: dateText },
      { id: "telefono-solicitante", valueString: dto.contact.phone.toString() },
      { id: "correo-solicitante", valueString: dto.contact.email },
    ],
    participant: [
      {
        actor: [
          {
            reference: `Patient/${
              dto.booking && dto.booking.user
                ? dto.booking.user.idDocCode +
                  "-" +
                  dto.booking.user.idDocNumber.toString()
                : ""
            }`,
            display: "",
          },
        ],
        status: "accepted",
      },
    ],
    resourceType: "Appointment",
    serviceType: [
      {
        coding: [
          {
            id:
              dto.booking && dto.booking.serviceType
                ? dto.booking.serviceType.key
                : "",
            display: "",
          },
        ],
        id: "id-tipo-examen",
      },
    ],
    slot: [
      {
        reference: `Slot/${dto.slot ? dto.slot.id : ""}`,
        display: "",
      },
    ],
    specialty: [
      {
        coding: [
          {
            id:
              dto.booking && dto.booking.especiality
                ? dto.booking.especiality.id.toString()
                : "",
            display: "",
          },
        ],
        id: "id-examen",
      },
    ],
  };
  return data;
}

export { prepScheduleQuery, mapBookingDtoToAppoinment };
