import { thunk } from "easy-peasy";
import axios from "../services/axios";
import _ from "lodash";
import { Especiality } from "../types/Booking/Especiality";
import { Location } from "../types/Booking/Location";
import { Partitioner } from "../types/Booking/Partitioner";
import { Slot } from "../types/Booking/Slot";
import { ServiceType } from "../types/HL7/ServiceType";
import moment from "../services/moment";
import { IdValuePair } from "../types/KeyValuePair";
import { User, LoginInput, RegisterInput } from "../types/User";
import { SlotBookingStatus } from "../types/Booking/SlotBookingStatus";
import { Extension } from "../types/HL7/Extension";
import { Reference } from "../types/HL7/Reference";
import { Coding } from "../types/HL7/Coding";
import { AppointmentReceived } from "../types/HL7/AppointmentReceived";
import { ParticipantReceived } from "../types/HL7/ParticipantReceived";
import { OnlyFor } from "../types/OnlyFor";
import { prepScheduleQuery, mapBookingDtoToAppoinment } from "./storeUtils";
import axiosService from "../services/axios";
import { BookingAppThunks } from "./storeModel/BookingAppThunks";
import store from "./store";
import { AppointmentCancelStatus } from "../types/Cancelling/AppointmentCancelStatus";
import { ParameterResource } from "../types/HL7/ParameterResource";
import { AxiosError } from "axios";

const SlashCharacter: string = "/";
const PractitionerIdPosition: number = 1;
const LocationIdPosition: number = 1;
const SpecialityIdPosition: number = 1;
const DefaultValueNumber: number = 0;
const PatientWithoutEmail = "";
const thunks: BookingAppThunks = {
  //Thunks
  getEspecialities: thunk(async (actions) => {
    const result = await axiosService?.get("/Specialty?_count=1000");
    const especialisties: Especiality[] = _.map<any, Especiality>(
      result.data.entry,
      (item) => {
        return {
          id: Number(item.resource.id),
          name: item.resource.display,
          partitionersIds: item.resource.practitioner.map((par: any) => {
            const ref = par.reference as string;
            return (
              ref.split(SlashCharacter)[PractitionerIdPosition] ??
              DefaultValueNumber
            );
          }),
        };
      }
    );
    actions.setEspecialities(especialisties as any);
  }),

  getLocations: thunk(async (actions, payload) => {
    const result = await axios.get(`/Specialty/${payload}/locations`);
    const locations: Location[] = _.map<any, Location>(
      result.data.entry,
      (item) => {
        return {
          id: Number(item.resource.id),
          name: item.resource.name,
        };
      }
    );
    actions.setLocations(locations as any);
  }),

  getPartitioners: thunk(async (actions, payload) => {
    const locationQuery = payload.locationId
      ? `IdSede=${payload.locationId}`
      : "";
    const specielityQuery =
      payload.specialityId > 0 ? `IdExamen=${payload.specialityId}` : "";
    const result = await axios.get(
      `/Practitioner?${specielityQuery}&${locationQuery}`
    );
    const practitioners: Partitioner[] = _.map<any, Partitioner>(
      result.data.entry,
      (item) => {
        return {
          id: item.resource.id,
          name: `${
            item.resource.name[0].family
          }, ${item.resource.name[0].given.join(" ")}`,
          position: "Dr.",
          especialityId: payload.specialityId,
        };
      }
    );
    actions.setPartitioners(practitioners as any);
  }),

  getSlots: thunk(async (actions, payload) => {
    try {
      const result = await axios.get(
        "api/v2/available-slot?" + prepScheduleQuery(payload)
      );
      let slots: Slot[] = _.map<any, Slot>(result.data.entry, (item) => {
        return {
          id: item.resource.id,
          practitionerId:
            item.resource.practitioner.reference.split(SlashCharacter)[
              PractitionerIdPosition
            ],
          practitionerName: item.resource.practitioner.display,
          date: new Date(item.resource.start),
          endDate: new Date(item.resource.end),
          locationId:
            item.resource.location.reference.split(SlashCharacter)[
              LocationIdPosition
            ],
          locationName: item.resource.location.display,
          specialityId:
            item.resource.specialties[0].reference.split(SlashCharacter)[
              SpecialityIdPosition
            ],
          officeId: item.resource.extension[1].valueString,
          confirmDialogIsVisible: false,
          status: item.resource.status,
          bookingStatus: SlotBookingStatus.LISTED,
        };
      });
      slots = slots
        .filter((item) => item.status === "free")
        .sort((slot1, slot2) => {
          return slot1.date > slot2.date ? 1 : -1;
        });
      actions.setSlots(slots as any);
    } catch (error) {
      actions.setSlots([] as any);
    }
  }),

  getServiceTypes: thunk(async (actions, payload) => {
    try {
      const result = await axios.get(
        "/ServiceType?IdExamen=" + payload.toString()
      );
      const serviceTypes: ServiceType[] = _.map<any, ServiceType>(
        result.data.entry,
        (item) => {
          return { key: item.resource.id, value: item.resource.display };
        }
      );
      actions.setServiceTypes(serviceTypes);
    } catch (error) {
      console.log("No se encontraron Tipos de examen");
    }
  }),

  getDocumentTypes: thunk(async (actions, payload) => {
    try {
      const result = await axios.get("api/TypeDocument");
      const documentTypes: IdValuePair[] = _.map<any, IdValuePair>(
        result.data,
        (item) => {
          return {
            id: Number(item.idDocumentoTipo),
            value: item.NomTipoDocumento,
          };
        }
      );
      actions.setDocumentTypes(documentTypes);
    } catch (error) {
      throw error.message;
    }
  }),

  getUserData: thunk(async (actions, payload) => {
    const userDataParams: ParameterResource = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "IdentificationType",
          valueString: payload.idDocType.toString(),
        },
        {
          name: "IdentificationNumber",
          valueString: payload.idDocNumber.toString(),
        },
      ],
    };
    try {
      const userData = await axios.post("/SearchUserData", userDataParams);
      const user = {
        idDocType: payload.idDocType,
        idDocNumber: payload.idDocNumber,
        userId: userData.data.IdUsuario,
        phone: userData.data.TelefonoFijo,
        cellphone: userData.data.Celular,
        email: userData.data.Correo,
        firstName: userData.data.NombrePrimer,
        secondName: userData.data.NombreSegundo,
        familyName: userData.data.ApellidoPrimer,
        lastName: userData.data.ApellidoSegundo,
        idDocName: userData.data.NomTipoDocumento,
        termsAndConditions: userData.data.AceptaTerminos,
        idDocCode: userData.data.CodigoDocumento,
      } as User;
      actions.setUser(user);
    } catch (error) {
      console.log("no se pudo traer al usuario");
    }
  }),

  resetPatientPassword: thunk(async (actions, payload) => {
    try {
      const params: ParameterResource = BuildParams(payload);

      const { data } = await axios.post("/PatientPassword", params);

      const email = data?.Email ?? PatientWithoutEmail;

      actions.setEmailPassword(email);
    } catch (error) {
      const errors = error as AxiosError;
      actions.setLoginError(errors?.response?.data.message_error || "");
    }
  }),

  getMyAppointments: thunk(async (actions, onlyFor) => {
    try {
      const user = store.getState().root.booking.user as User;
      actions.setMyAppointments([]);
      const today = moment();
      const prefixFor = onlyFor === OnlyFor.Future ? "ge" : "le";
      const result = await axios.get(
        "/Appointment?patient=" +
          user.idDocCode +
          "-" +
          user.idDocNumber +
          "&Start=" +
          prefixFor +
          today.format("YYYY-MM-DD") +
          "&_count=100" +
          (prefixFor === "le" ? "&Start=1900-01-01" : "")
      );

      let appointments: AppointmentReceived[] = _.map(
        result.data.entry,
        function (item) {
          const appointment = {
            id: Number(item.resource.id),
            extension: item.resource.extension as Extension[],
            participant: item.resource.participant as ParticipantReceived[],
            resourceType: item.resource.resourceType as string,
            slot: item.resource.slot as Reference[],
            specialty: item.resource.specialty as Coding[],
            start: new Date(item.resource.start),
            end: new Date(item.resource.end),
            status: item.resource.status,
            cancellingStatus: AppointmentCancelStatus.LISTED,
          } as AppointmentReceived;

          appointment.extension.push({
            id: "razon-cancelacion",
            valueString: "",
          });

          return appointment;
        }
      );

      //appointments = appointments.filter((item) => item.status === "booked");

      actions.setMyAppointments(appointments);
    } catch (error) {
      console.log(error);
    }
  }),

  sendAppointment: thunk(async (actions, payload) => {
    const appointment = mapBookingDtoToAppoinment(payload);
    try {
      actions.switchConfirmStatus({
        key: payload.slot.id,
        value: SlotBookingStatus.ON_REQUEST,
      });
      await axios.post("api/v2/Appointment", appointment);
      actions.switchConfirmStatus({
        key: payload.slot.id,
        value: SlotBookingStatus.CONFIRMED,
      });
    } catch (error) {
      actions.switchConfirmStatus({
        key: payload.slot.id,
        value: SlotBookingStatus.DENIED,
      });
      console.log(error);
    }
  }),

  sendLogin: thunk(async (actions, payload) => {
    const loginParams: ParameterResource = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "IdentificationType",
          valueString: payload.idDocType.toString(),
        },
        {
          name: "IdentificationNumber",
          valueString: payload.idDocNumber.toString(),
        },
        {
          name: "Password",
          valueString: payload.password,
        },
      ],
    };
    try {
      await axios.post("/ValidateUserByLogin", loginParams);
      await actions.getUserData({
        idDocType: payload.idDocType,
        idDocNumber: payload.idDocNumber,
      } as LoginInput);
    } catch (error) {
      if (error.response.status >= 400 && error.response.status < 500) {
        actions.setLoginError("Los datos de inicio de sesión son incorrectos");
      } else {
        actions.setStoreError(error);
      }
    }
  }),

  cancelAppointment: thunk(async (actions, payload) => {
    const { cancellingStatus, ...withOutCancellingStatus } = payload;
    try {
      actions.changeAppointmentCancelStatus({
        id: payload.id,
        newCancellingStatus: AppointmentCancelStatus.ON_REQUEST,
      });
      withOutCancellingStatus.status = "cancelled";
      await axios.put("/Appointment", withOutCancellingStatus);
      actions.changeAppointmentCancelStatus({
        id: payload.id,
        newCancellingStatus: AppointmentCancelStatus.CANCELLED,
      });
    } catch (error) {
      actions.changeAppointmentCancelStatus({
        id: payload.id,
        newCancellingStatus: AppointmentCancelStatus.DENIED,
      });
    }
  }),
};

export default thunks;

export function BuildParams<T extends {}>(payload: T) {
  return {
    resourceType: "Parameters",
    parameter: Object.entries(payload).map(([name, value]: any) => ({
      name,
      valueString: value.toString(),
    })),
  };
}
