import { BookingAppActions } from "./storeModel/BookingAppActions";
import { action } from "easy-peasy";
import { Partitioner } from "../types/Booking/Partitioner";
import { SlotBookingStatus } from "../types/Booking/SlotBookingStatus";
import CopyAndSortBy from "../services/CopyAndSortBy";
import { AppointmentCancelStatus } from "../types/Cancelling/AppointmentCancelStatus";

const actions: BookingAppActions = {
  setStoreError: action((state, payload) => {
    state.storeError = payload;
  }),

  //action setter
  setEntities: action((state, payload) => {
    state.entities = payload;
  }),
  setEspecialities: action((state, payload) => {
    state.especialities = payload;
  }),
  setLocations: action((state, payload) => {
    state.locations = payload;
  }),
  setPartitioners: action((state, payload) => {
    var partitioners = payload as Partitioner[];
    state.partitioners = partitioners.map((item) => {
      item.especiality = state.especialities.find(
        (e) => e.id === item.especialityId
      );
      return item;
    });
  }),
  setSlots: action((state, payload) => {
    state.slots = payload;
  }),
  toggleConfirmPanel: action((state, slotId) => {
    const slot = state.slots.find((id) => id.id === slotId);
    if (slot) {
      slot.confirmDialogIsVisible = !slot.confirmDialogIsVisible;
      state.booking.slot = slot;
    }
  }),
  switchConfirmStatus: action((state, payload) => {
    let slot = state.slots.find((item) => item.id === payload.key);
    if (slot)
      slot.bookingStatus =
        SlotBookingStatus[payload.value as keyof typeof SlotBookingStatus];
  }),
  setServiceTypes: action((state, payload) => {
    state.serviceTypes = payload;
  }),
  setDocumentTypes: action((state, payload) => {
    state.documentTypes = payload;
  }),
  setMyAppointments: action((state, payload) => {
    state.myAppointments = payload;
  }),

  //Actions Setters
  setUser: action((state, payload) => {
    state.loginError = "";
    state.booking.user = payload;
    state.slots = [];

    localStorage.setItem("citasuser", JSON.stringify(state.booking.user));
  }),
  setEspeciality: action((state, payload) => {
    const esp = state.especialities.find((item) => item.id === payload);
    state.booking.especiality = esp;
  }),
  setLocation: action((state, payload) => {
    const location = state.locations.find((item) => item.id === payload);
    state.booking.location = location;
  }),
  setPartitioner: action((state, payload) => {
    state.booking.partitioner = payload;
  }),
  setSlot: action((state, payload) => {
    state.booking.slot = payload;
  }),
  setAxiosIsOnRequest: action((state, payload) => {
    state.axiosIsOnRequest = payload;
  }),

  setServiceType: action((state, payload) => {
    let service = state.serviceTypes.find((item) => item.key === payload);
    state.booking.serviceType = service;
  }),
  deleteSlot: action((state, payload) => {
    state.slots = state.slots.filter((item) => item.id !== payload);
  }),
  deleteUser: action((state) => {
    state.booking.user = undefined;
    localStorage.clear();
  }),
  sortMyAppointments: action((state, payload) => {
    state.myAppointments = CopyAndSortBy(
      state.myAppointments,
      payload.columnKey,
      payload.isSortedDescending
    );
  }),
  setEmailPassword: action((state, payload) => {
    state.emailPassword = payload;
  }),
  setLoginError: action((state, error) => {
    state.loginError = error;
  }),
  sortSlots: action((state, payload) => {
    state.slots = CopyAndSortBy(
      state.slots,
      payload.columnKey,
      payload.isSortedDescending
    );
  }),
  changeAppointmentCancelStatus: action((state, payload) => {
    let appointment = state.myAppointments.find(
      (item) => item.id === payload.id
    );
    if (appointment) {
      appointment.cancellingStatus = payload.newCancellingStatus;
      if (appointment.cancellingStatus === AppointmentCancelStatus.CANCELLED) {
        appointment.status = "cancelled";
      }
    }
  }),
  deleteMyAppointment: action((state, payload) => {
    state.myAppointments = state.myAppointments.filter(
      (item) => item.id !== payload
    );
  }),
};

export default actions;
