import React from 'react';
import Login from './components/Login';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import Search from './components/Search'
import store from './store/store';
import { StoreProvider } from 'easy-peasy';
import '../src/App.css'
import MyAppointments from './components/MyAppointments';
import Register from './components/Register';
import ProtectedRoute from './components/ProtectedRoute';
import ErrorBoundary from './components/ErrorBoundary';


initializeIcons()

interface AppProps {
  error?: any
}

const App: React.SFC<AppProps> = ({error})=>{

    return (
      <StoreProvider store={store}>
        <ErrorBoundary>
          <Router>
            <Route path="/" exact render={() => <Login></Login>}></Route>
            <Route
              path="/register"
              exact
              render={() => <Register></Register>}
            ></Route>
            <ProtectedRoute
              path="/myappointments"
              component={MyAppointments}
            ></ProtectedRoute>
            <ProtectedRoute path="/search" component={Search}></ProtectedRoute>
          </Router>
        </ErrorBoundary>
      </StoreProvider>
    );
  }

export default App;
