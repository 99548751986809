export interface Cancel_i18n {
    areYouSure: string
    introRaison: string
    raisonPlaceHolder: string 
    yesImSureButton: string 
    sendingCancelRequest: string 
    waitWhileCancelling: string 
    appointmentCancelled: string
    cancelRequestDenied: string
}

export enum Cancel_i18n_Keys {
    areYouSure= 'areYouSure',
    introRaison= 'introRaison',
    raisonPlaceHolder= 'raisonPlaceHolder' ,
    yesImSureButton= 'yesImSureButton' ,
    sendingCancelRequest= 'sendingCancelRequest' ,
    waitWhileCancelling= 'waitWhileCancelling' ,
    appointmentCancelled= 'appointmentCancelled', 
    cancelRequestDenied = 'cancelRequestDenied'
}